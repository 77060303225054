export const initHomeFacs = () => {
	const facs = document.querySelectorAll('#home_facs_selector button');
	document.querySelector('#home_facs_selector').addEventListener('click', e => {
		const clickedFac = e.target.closest('button');

		if(clickedFac) {
			facs.forEach(fac => fac.removeAttribute('active'));
			clickedFac.setAttribute('active', true);
		}

		document.querySelector('#home_facs_img').style.backgroundImage = `url('${clickedFac.getAttribute("fac-img")}')`;
		document.querySelector('#home_facs_text p').innerHTML = clickedFac.getAttribute('fac-text');
		document.querySelector('#home_facs_text .btn').href = clickedFac.getAttribute('fac-link');
	});

	document.querySelector('#home_facs_selector button').click();
};