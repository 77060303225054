export const initMobileFooter = () => {
	window.addEventListener('scroll', debounce(mobileFooterCheck, 250));

};

const mobileFooterCheck = () => {
	const mobileFooter = document.querySelector('#cta_floaty_links');

	if(window.innerWidth <= 750) {
		if(window.scrollY) {
			mobileFooter.setAttribute('show', true);
		} else {
			mobileFooter.removeAttribute('show');
		}
	} else {
		mobileFooter.removeAttribute('show');
	}
};

function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		// eslint-disable-next-line no-invalid-this
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}