import { AutomatitCarousel } from './automatit_carousel.m';
import { getMapsHelper } from './util/mapsHelper';
import { multiMap } from './multiMap';

export const initFacilityCaro = () => {
	const options = {
		element: document.querySelector('#fac_caro'),
		images: window.facCaroImgs,
		imagesAsBackgrounds: true,
		showDots: false,
		useChevrons: true,
	};
    
	if(window.facCaroImgs.length === 1) {
		options['intervalTiming'] = false;
		options['useChevrons'] = false;
	}
	AutomatitCarousel(options);
};

export const initFacilityMap = () => {
	const mapsHelper = getMapsHelper();

	mapsHelper.ready().then(() => {
		const theMap = mapsHelper.createMap({
			element: document.querySelector('#fac_map'),
			locationElementSelector: '#fac_map',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `<a ${(el.hasAttribute('blank'))?'target="_blank"':''} href="${el.getAttribute('link')}" class="map_pin"><img src="/dist/images/icons/map_pin.svg" alt="" /></a>`
				};
			}
		});

		
		// eslint-disable-next-line no-undef
		google.maps.event.addListenerOnce(theMap, 'idle', () => {
			theMap.setZoom(15);
		});
	});
};

export const toggleMap = () => {
	const mapSlide = document.getElementById('fac_map');
	const mapToggle = document.getElementById('fac_view_map');
	const photoToggle = document.getElementById('fac_view_photos');
    
	mapToggle.addEventListener('click', function(){
		mapToggle.classList.add('active');
		photoToggle.classList.remove('active');
		mapSlide.classList.add('active');
	});
	photoToggle.addEventListener('click', function(){
		mapToggle.classList.remove('active');
		photoToggle.classList.add('active');
		mapSlide.classList.remove('active');
	});
};

export const toggleFacSections = () => {
	[].forEach.call(document.querySelectorAll('.fac_section_header'), (el) => {
		el.addEventListener('click', (e) => {
			let button = e.currentTarget;
			if(button.classList.contains('open')){
				button.classList.remove('open');
				button.nextElementSibling.classList.remove('open');
			}else{
				button.classList.add('open');
				button.nextElementSibling.classList.add('open');
			}
		});
	});
    
	if(window.innerWidth > 750){
		setTimeout(() => {
			[].forEach.call(document.querySelectorAll('.fac_section_header'), (el) => {
				el.click();
			});
		}, 500);
	}
};

export const toggleFeatures = () => {
	document.getElementById('fac_feat_more').addEventListener('click', function(){
		document.getElementById('fac_more_features_wrapper').classList.add('open');
	});
};

export const toggleFacilityTabs = () => {
	const tabs = document.querySelectorAll('#fac_page_tabs .tab');
	[].forEach.call(tabs, (el) => {
		el.addEventListener('click', (e) => {
			let tab = e.currentTarget;
			[].forEach.call(tabs, (el) => {
				el.classList.remove('active');
			});
			tab.classList.add('active');
            
			document.querySelector('.fac_page_tab_content.active').classList.remove('active');
			document.querySelector('#fac_page_'+tab.dataset.content).classList.add('active');
            
			if(tab.dataset.content === 'nearby'){
				multiMap();
			}
		});
	});
};

export const filterUnits = () => {
	const filters = document.querySelectorAll('#fac_page_filters .filter');
	const units = document.querySelectorAll('#rates .unit_row');
	[].forEach.call(filters, (filter) => {
		filter.addEventListener('click', (e) => {
			let filter = e.currentTarget;
			[].forEach.call(filters, (el) => {
				el.classList.remove('active');
			});
			filter.classList.add('active');
            
			let visibleUnits = 0;
			[].forEach.call(units, (unit) => {
				unit.classList.add('hidden');
				if(unit.dataset.size === filter.dataset.size || filter.dataset.size === 'all'){
					unit.classList.remove('hidden');
					visibleUnits++;
				}
			});
            
			if(visibleUnits > 0){
				document.getElementById('fac_no_units').style.display = 'none';
			}else{
				document.getElementById('fac_no_units').style.display = 'block';
			}
		});
	});
};

export const largeFilter = () => {
	if(window.location.search === "?large"){

		//Gather filters
		const filters = document.querySelectorAll('#fac_page_filters .filter');
	
		//Setting up lg filter variable
		let largeFilter = '';
	
		//Getting lg filter
		filters.forEach((filter) => {
			if(filter.getAttribute('data-size') === 'lrg'){
				largeFilter = filter;
			}
		});
		
		//Trigger lg filter
		largeFilter.click();
		
		//Scroll
		const ratesTable = document.getElementById('fac_page_rates');
		ratesTable.scrollIntoView();
	}
};