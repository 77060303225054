/* eslint-disable no-undef */
import { getMapsHelper } from './util/mapsHelper';

export const multiMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#inc_map'),
				locationElementSelector: '.inc_fac_item',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `
							<a class="map_pin" href="${el.getAttribute('link')}">
								<img src="/static/images/icons/map_pin.svg" alt="Visit facility" />
								<span>${el.getAttribute('count')}</span>
							</a>
						`
					};
				},
			});

			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				if(document.querySelectorAll('.inc_fac_item').length < 2) {				
					theMap.setZoom(15);
				}

				document.querySelectorAll('.inc_fac_item').forEach(i => {
					const markerData = mapHelper.getMarker(i);
					markerData.element.addEventListener('mouseenter', () => {
						markerData.element.classList.add('hover');
						i.classList.add('hover');
					});
					markerData.element.addEventListener('mouseleave', () => {
						markerData.element.classList.remove('hover');
						i.classList.remove('hover');
					});
				});
			});
		});
};
