export const tipsOpenClose = () => {
	[].forEach.call(document.querySelectorAll('#tips_content h4'), (el) => {
		el.addEventListener('click', (e) => {
			let tip = e.currentTarget;
			if(tip.classList.contains('open')) {
				tip.classList.remove('open');
				tip.nextElementSibling.classList.remove('open');
			} else {
				tip.classList.add('open');
				tip.nextElementSibling.classList.add('open');
			}
		});
	});
	setTimeout(() => {
		document.querySelector('#tips_content h4').click();
	}, 500);
};