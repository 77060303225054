import './polyfills';

import { initMobileNav, initOverlays, initDoubleClick } from './header';
import { initMobileFooter } from './footer';

import { initHomeFacs } from './home';
import { initReviewsCaro } from './reviews';

import { multiMap } from './multiMap';

import { contactSubmit } from './contact.js';
import { initFacilityCaro, initFacilityMap, toggleMap, toggleFacSections, toggleFeatures, toggleFacilityTabs, filterUnits, largeFilter } from './facility';
import { tipsOpenClose } from './help.js';

if(document.querySelector('#fac_page')) {
	initFacilityCaro();
	initFacilityMap();
	toggleMap();
	toggleFacSections();
	toggleFacilityTabs();
	filterUnits();
	largeFilter();
}

if(document.querySelector('#fac_feat_more')) {
	toggleFeatures();
}

initMobileNav();
initDoubleClick();
initOverlays();
initMobileFooter();

if(document.getElementById('tips_content')) {
	tipsOpenClose();
}

if(document.querySelector('#move_out_form')) {
	document.querySelector('#move_out_form').addEventListener('submit', e => {
		document.querySelector('#move_out_form .response_message').innerHTML = '';
		let uncheckedBoxes = [];
		document.querySelectorAll('#move_out_form input[type="checkbox"]').forEach(box => {
			if(!box.checked) {
				uncheckedBoxes.push(box.id);
			}
		});
		console.log(uncheckedBoxes);
		if(uncheckedBoxes.length) {
			e.preventDefault();
			e.stopImmediatePropagation();
			document.querySelector('#move_out_form .response_message').innerHTML = "<span class='error'>All checkboxes must be selected.</span>";
		}
	});
	contactSubmit({
		elementSelector: '#move_out_form',
		required: ['mo_name', 'unit']
	});
}

if(document.querySelector('#home_facs')) {
	initHomeFacs();
}

if(document.querySelector('#reviews_caro')) {
	initReviewsCaro();
}

if(document.querySelector('#inc_map')) {
	multiMap();
}

if (document.getElementById('contact_form')) {
	contactSubmit({
		elementSelector: '#contact_form',
		required: ['name', 'facility', 'email', 'message']
	});
}
