export const initMobileNav = () => {	
	const mobileNav = document.querySelector('#toggle_mobile_nav');
	mobileNav.addEventListener('click', () => {
		mobileNav.switchAttribute('state', 'open', 'closed');
		document.querySelector('#nav').switchAttribute('state', 'open', 'closed');
	});
};

export const initOverlays = () => {
	const overlay = document.querySelector('#header_overlay');
	overlay.addEventListener('click', e => {
		if(e.target.id === 'header_overlay' || e.target.closest('button.close')) {
			overlay.removeAttribute('show');
		}
	});
	window.showOverlay = type => {
		const inner = overlay.querySelector(`.header_overlay_content[${type}]`);
		if(inner) {
			document.querySelectorAll('.header_overlay_content').forEach(o => o.removeAttribute('show'));
			overlay.setAttribute('show', true);
			inner.setAttribute('show', true);
		}
	};
};

export const initDoubleClick = () => {
	document.querySelectorAll('nav a[d_click]').forEach(i => {
		i.addEventListener('click', e => {
			if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
				if(!i.hasAttribute('clicked') && (window.innerWidth > 750 || i.hasAttribute('mobile_d_click'))) {
					e.preventDefault();
					i.setAttribute('clicked', true);
				}
			}
		});
	});
};